<template>
  <v-container fluid>
    <v-locale-text-field
      v-model="name"
      label="Name"
      required
      :error-messages="errors.collect('name')"
      data-vv-name="name"
    />
    <v-text-field
      v-model="price"
      v-validate="'max:255|numeric'"
      label="Price"
      autocomplete="not-my-price"
      :counter="255"
      :error-messages="errors.collect('price')"
      data-vv-name="price"
      required
    />
    <v-switch v-model="isRoot" label="is root"></v-switch>
    <v-autocomplete-category
      v-model="parents"
      multiple
      label="Parents"
      :disabled="isRoot"
    />
    <v-autocomplete-category v-model="children" multiple label="Children" />
  </v-container>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";
import VAutocompleteCategory from "./VAutocompleteCategory";
import VLocaleTextField from "../generic/input/VLocaleTextField";
const { mapComputed } = createNamespacedHelpers("category");

export default {
  name: "category",
  components: { VLocaleTextField, VAutocompleteCategory },
  inject: ["$validator"],
  data: () => ({
    localParents: []
  }),
  computed: {
    ...mapComputed(["isRoot", "parents", "children", "name", "price"])
  },
  created() {
    this.localParents = this.parents;
  },
  watch: {
    isRoot(val) {
      if (!val) {
        this.parents = this.localParents;
      } else {
        this.parents = [];
      }
    }
  }
};
</script>

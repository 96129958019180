<template>
  <v-layout>
    <v-flex>
      <overview-header
        :title="title"
        :icon="icon"
        :store="store"
        :searchable="searchable"
      >
        <template slot="toolbar-additional-search">
          <slot name="toolbar-additional-search" />
        </template>
        <template slot="toolbar-actions">
          <slot name="toolbar-actions" />
          <v-btn icon small @click="expandAll">
            <v-icon>{{ expandBoolean ? "unfold_less" : "unfold_more" }}</v-icon>
          </v-btn>
        </template>
      </overview-header>
      <v-card>
        <v-container>
          <v-layout align-center justify-center>
            <v-progress-circular
              v-if="isLoading"
              :size="70"
              :width="5"
              :color="isError ? 'red' : 'primary'"
              indeterminate
            ></v-progress-circular>
          </v-layout>
          <slot name="content">
            <v-treeview
              slot="content"
              activatable
              open-on-click
              transition
              :items="tree"
              :item-text="'name.nl_be'"
              class="custom-tree"
              ref="tree"
            >
              <template slot="append" slot-scope="props">
                <slot v-bind="props" name="tree-append" />
              </template>
            </v-treeview>
          </slot>
        </v-container>
      </v-card>
    </v-flex>
    <overview-actions :actions="actions" />
  </v-layout>
</template>
<script>
import OverviewActions from "./OverviewActions";
import OverviewHeader from "./OverviewHeader";
import {
  IDS as GETTER_IDS,
  IS_ERROR as GETTER_IS_ERROR,
  IS_LOADING as GETTER_IS_LOADING
} from "@/store/templates/table/getter-types";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";

export default {
  name: "tree-view-overview",
  components: {
    OverviewHeader,
    OverviewActions
  },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: undefined
    },
    store: {
      type: String,
      required: true
    },
    actions: {
      type: Array,
      default: () => []
    },
    searchable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    expandBoolean: false
  }),
  created() {
    this.loadData();
  },
  computed: {
    isLoading() {
      return this.$store.getters[`${this.store}/${GETTER_IS_LOADING}`];
    },
    isError() {
      return this.$store.getters[`${this.store}/${GETTER_IS_ERROR}`];
    },
    ids() {
      return this.$store.getters[`${this.store}/${GETTER_IDS}`];
    },
    tree() {
      return this.$store.getters[`${this.store}/tree`];
    }
  },
  methods: {
    loadData() {
      this.$store.dispatch(`${this.store}/${DISPATCH_FETCH}`);
    },
    expandAll() {
      this.expandBoolean = !this.expandBoolean;
      //TODO remove this when vuetify expands there open-all functionality
      this.$refs.tree.updateAll(this.expandBoolean);
    }
  }
};
</script>
<style scoped></style>

<template>
  <div>
    <v-btn
      icon
      tag="a"
      :to="{
        name: 'categories.show',
        params: { categoryId: category.id }
      }"
    >
      <v-icon>far fa-search</v-icon>
    </v-btn>
    <v-chip-subject
      v-for="subject in subjects"
      :key="subject.id"
      :subject="subject"
    />
  </div>
</template>
<script type="text/babel">
import VChipSubject from "@/components/subject/VChipSubject";

export default {
  name: "category-tree-append",
  components: { VChipSubject },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    subjects() {
      return this.$store.getters["categories/subjectsByCategoryId"](
        this.category.id
      );
    }
  }
};
</script>

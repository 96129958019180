<template>
  <v-toolbar color="primary" dark>
    <v-icon v-if="icon" @click="test">
      {{ icon }}
    </v-icon>
    <v-toolbar-title class="text-uppercase">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <slot name="toolbar-additional-search" />
    <overview-header-search v-if="searchable" :store="store" />
    <slot name="toolbar-actions" />
    <v-btn v-if="sidebar" icon @click="$emit('toggle-sidebar')">
      <v-icon :color="hasActiveFilters ? 'accent' : undefined">
        {{ sidebarIcon }}
      </v-icon>
    </v-btn>
  </v-toolbar>
</template>
<script>
import OverviewHeaderSearch from "./OverviewHeaderSearch";
import { HAS_ACTIVE_FILTERS as GETTER_HAS_ACTIVE_FILTERS } from "../../../store/templates/table/getter-types";

export default {
  name: "overview-header",
  components: {
    OverviewHeaderSearch
  },
  props: {
    title: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false,
      default: undefined
    },
    store: {
      type: String,
      required: true
    },
    searchable: {
      type: Boolean,
      default: false
    },
    sidebar: {
      type: Boolean,
      default: false
    },
    sidebarIcon: {
      type: String,
      default: "far fa-fw fa-filter"
    }
  },
  computed: {
    hasActiveFilters() {
      return this.$store.getters[`${this.store}/${GETTER_HAS_ACTIVE_FILTERS}`];
    }
  },
  methods: {
    test() {
      debugger;
    }
  }
};
</script>

import initialCrudState from "@/store/templates/crud/state";

const initialModel = () => ({
  id: null,
  isRoot: false,
  name: null,
  parents: [],
  children: [],
  price: null,
  categorySubjects: []
});

export default () => ({
  ...initialCrudState(initialModel),
  categorySubject: {},
  subject: {},
  questionOption: {},
  question: {},
  specifier: {},
  localization: {}
});

<template>
  <tree-view-overview
    v-if="!showChild"
    store="categories"
    title="Categories"
    icon="far fa-fw fa-tags"
    :actions="actions"
    ref="tree-overview"
  >
    <category-tree-append
      slot="tree-append"
      slot-scope="{ item }"
      :category="item"
    />
  </tree-view-overview>
  <router-view v-else />
</template>
<script type="text/babel">
import categoriesModule from "@/store/modules/categories";
import { mapOverviewLifecycleMethods } from "../helpers";
import TreeViewOverview from "@/components/generic/overview/TreeViewOverview";
import CategoryTreeAppend from "@/views/categories/CategoryTreeAppend";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "categories",
  categoriesModule
);

export default {
  name: "categories-view",
  components: { CategoryTreeAppend, TreeViewOverview },
  data: () => ({
    actions: [
      {
        icon: "far fa-fw fa-plus",
        color: "pink",
        to: { name: "categories.create" },
        rolesOrPermissions: "category_create"
      }
    ],
    expandBoolean: false
  }),
  computed: {
    showChild() {
      return (
        this.$route.matched.filter(route => route.name !== undefined).length > 1
      );
    }
  },
  ...overviewLifecycleMethods
};
</script>
<style>
.custom-tree .v-treeview-node__label {
  flex-grow: 0 !important;
  margin-right: 10px;
}
</style>

<template>
  <category />
</template>
<script type="text/babel">
import Category from "@/components/category/Category";

export default {
  name: "category-dashboard-view",
  components: { Category }
};
</script>

import crudGetters from "@/store/templates/crud/getters";
import {
  assignInWith,
  cloneDeep,
  defaults,
  filter,
  includes,
  isNil,
  isObject,
  map
} from "lodash";
import { denormalize } from "normalizr";
import { question as questionSchema } from "@/store/schema";

export default {
  ...crudGetters,
  category: state => state.model,
  id: state => state.model.id,
  isRoot: state => state.model.isRoot,
  name: state => state.model.name,
  price: state => state.model.price,
  parents: state => state.model.parents,
  children: state => state.model.children,
  categorySubjects: state =>
    state.model.categorySubjects.map(categorySubjectId => {
      return Number.isInteger(categorySubjectId)
        ? state.categorySubject[categorySubjectId]
        : categorySubjectId;
    }),
  categorySubject: state => id => state.categorySubject[id],
  subject: state => id => state.subject[id],
  getQuestionById: (state, getters) => ({ id }) => {
    let question = cloneDeep(state.question[id]);
    if (!isNil(question) && !isNil(question.parent)) {
      const parentQuestion = getters.getQuestionById({ id: question.parent });
      assignInWith(question, parentQuestion, (objValue, parentValue) => {
        if (isObject(objValue) || isObject(parentValue)) {
          // Double loop for localization fields
          assignInWith(objValue, parentValue, (secObjValue, secParentValue) => {
            return isNil(secObjValue) ? secParentValue : secObjValue;
          });
        }
        return isNil(objValue) ? parentValue : objValue;
      });

      question = defaults(question, parentQuestion);
    }
    return question;
  },
  getQuestionsByIds: (state, getters) => ({
    ids,
    types,
    isExcludedIncluded
  }) => {
    let questions = map(ids, id => getters.getQuestionById({ id: id }));
    if (types) {
      questions = filter(questions, question => includes(types, question.type));
    }
    return isExcludedIncluded
      ? questions
      : filter(questions, question => !question.isExcluded);
  },
  getQuestionsByIdsNormalized: (state, getters) => ({
    ids,
    types,
    isExcludedIncluded
  }) =>
    getters
      .getQuestionsByIds({
        ids,
        types,
        isExcludedIncluded
      })
      .map(question => denormalize(question, questionSchema, state)),
  getQuestionOptionById: (state, getters) => ({ id }) => {
    let questionOption = cloneDeep(state.questionOption[id]);
    if (!isNil(questionOption) && !isNil(questionOption.parent)) {
      const parentQuestionOption = getters.getQuestionOptionById({
        id: questionOption.parent
      });
      assignInWith(
        questionOption,
        parentQuestionOption,
        (objValue, parentValue) => {
          if (isObject(objValue) || isObject(parentValue)) {
            // Double loop for localization fields
            assignInWith(
              objValue,
              parentValue,
              (secObjValue, secParentValue) => {
                return isNil(secObjValue) ? secParentValue : secObjValue;
              }
            );
          }
          return isNil(objValue) ? parentValue : objValue;
        }
      );
      questionOption = defaults(questionOption, parentQuestionOption);
    }
    if (isNil(questionOption)) {
      console.warn(
        `Tried to retrieve entity questionOption with id ${id} but received ${questionOption}`
      );
    }
    return questionOption;
  },
  getQuestionOptionsByIds: (state, getters) => ids => {
    return map(ids, id => getters.getQuestionOptionById({ id: id }));
  },
  getSpecifierForQuestion: state => ({
    categorySubjectIndex,
    rootQuestionId
  }) => {
    return state.model.categorySubjects[categorySubjectIndex].specifiers.find(
      specifier => specifier.question === rootQuestionId
    );
  }
};

<template>
  <v-chip label small :color="color" :dark="dark" :selected="selected">
    <v-icon-sector v-if="subject.type === 'SECTOR'" />
    <v-icon-subsector v-else-if="subject.type === 'SUBSECTOR'" />
    <v-icon-job v-else />
    {{ subject.name }}
  </v-chip>
</template>
<script type="text/babel">
import VIconSector from "@/components/subject/VIconSector";
import VIconSubsector from "@/components/subject/VIconSubsector";
import VIconJob from "@/components/subject/VIconJob";

export default {
  name: "v-chip-subject",
  components: { VIconJob, VIconSubsector, VIconSector },
  props: {
    subject: {
      type: Object,
      required: true
    },
    color: {
      type: String,
      default: undefined
    },
    dark: {
      type: Boolean,
      default: false
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

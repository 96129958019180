<template>
  <v-autocomplete
    v-model="model"
    v-validate="internalValidationRules"
    :name="internalName"
    :data-vv-name="internalName"
    :data-vv-as="label"
    :error-messages="internalErrorMessages"
    :items="items"
    :label="internalLabel"
    item-text="name.nl_be"
    :item-value="itemValue"
    :readonly="isReadOnly"
    :disabled="isDisabled"
    :multiple="isMultiple"
    :clearable="clearable"
    :loading="isLoading"
    :search-input.sync="search"
    :chips="isMultiple"
    persistent-hint
    :browser-autocomplete="browserAutocomplete"
    prepend-icon="far fa-fw fa-tags"
    @change="onChange"
  >
    <template v-slot:selection="{ item, selected }" v-if="isMultiple">
      <v-chip-category :category="item" :selected="selected" />
    </template>
  </v-autocomplete>
</template>

<script type="text/babel">
import VAutocompleteMixin from "@/components/mixins/input/VAutocompleteMixin";
import VChipCategory from "@/components/category/VChipCategory";

export default {
  name: "v-autocomplete-category",
  components: { VChipCategory },
  inject: ["$validator"],
  mixins: [VAutocompleteMixin],
  props: {
    leaf: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    store: "category-select",
    defaultLabel: "Category",
    defaultLabelMultiple: "Categories"
  }),
  watch: {
    leaf: {
      handler(newValue) {
        this.getter = newValue ? "leafs" : undefined;
      },
      immediate: true
    }
  }
};
</script>

import crudMutations from "@/store/templates/crud/mutations";
import { findIndex, cloneDeep } from "lodash";
import { setEntities } from "@/store/templates/entities";
import { SET_MODEL } from "../../templates/crud/mutation-types";
import Vue from "vue";

export default {
  ...crudMutations,
  [SET_MODEL](state, { entities, id }) {
    const initialModel = cloneDeep(entities.entity[id]);
    // clone enough for localizations, as they only contain string properties. Object.assign({}, target) would work
    // too, but if target is null or undefined this would give an empty object rather than null or undefined.
    initialModel.categorySubjects = initialModel.categorySubjects.map(id =>
      cloneDeep(entities.categorySubject[id])
    );
    Vue.set(state, "initialModel", initialModel);
    Vue.set(state, "model", cloneDeep(initialModel));

    setEntities(state, entities);
  },

  setIsRoot(state, isRoot) {
    state.model.isRoot = isRoot;
  },
  setParents(state, parents) {
    state.model.parents = parents;
  },
  setChildren(state, children) {
    state.model.children = children;
  },
  setName(state, name) {
    state.model.name = name;
  },
  setPrice(state, price) {
    state.model.price = price;
  },
  addSpecifier(state, specifier) {
    state.model.categorySubjects[
      specifier.categorySubjectIndex
    ].specifiers.push(specifier.defaultSpecifier);
  },
  removeSpecifier(state, specifier) {
    const index = findIndex(
      state.model.categorySubjects[specifier.categorySubjectIndex].specifiers,
      ["question", specifier.question.rootId]
    );
    state.model.categorySubjects[
      specifier.categorySubjectIndex
    ].specifiers.splice(index, 1);
  },
  setSpecifierOptions(state, specifier) {
    const index = findIndex(
      state.model.categorySubjects[specifier.categorySubjectIndex].specifiers,
      ["question", specifier.rootQuestionId]
    );
    state.model.categorySubjects[specifier.categorySubjectIndex].specifiers[
      index
    ].options = specifier.options;
  },
  setSpecifierType(state, specifier) {
    const index = findIndex(
      state.model.categorySubjects[specifier.categorySubjectIndex].specifiers,
      ["question", specifier.rootQuestionId]
    );
    state.model.categorySubjects[specifier.categorySubjectIndex].specifiers[
      index
    ].type = specifier.type;
  },
  addSubject(state, subject) {
    setEntities(state, subject.entities);
    const categorySubject = {
      id: null,
      specifiers: [],
      subject: subject.subjectId
    };
    state.model.categorySubjects.push(categorySubject);
  },
  removeSubject(state, index) {
    state.model.categorySubjects.splice(index, 1);
  }
};

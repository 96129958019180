<template>
  <v-card color="white">
    <crud-header
      type="Category"
      store="category"
      icon="far fa-fw fa-tags"
      :tabs="tabs"
    />
  </v-card>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import categoryModule from "@/store/modules/category";
import { mapDetailViewLifecycleMethods } from "../helpers";
import { isNil } from "lodash";
import questionsModule from "@/store/modules/questions";
import store from "@/store";

const { mapGetters } = createNamespacedHelpers("category");
const detailViewLifecycleMethods = mapDetailViewLifecycleMethods(
  "category",
  categoryModule
);

export default {
  name: "category-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    CrudHeader
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["id"]),
    tabs() {
      return isNil(this.id)
        ? []
        : [
            {
              name: "Details",
              to: { name: "categories.show", params: { categoryId: this.id } }
            },
            {
              name: "Subjects",
              to: {
                name: "categories.subjects",
                params: { categoryId: this.id }
              }
            }
          ];
    }
  },
  created() {
    this.registerQuestions();
  },
  methods: {
    registerQuestions() {
      store.registerModule("questions", questionsModule);
      this.$store.dispatch("questions/fetchAlwaysVisible");
    }
  },
  ...detailViewLifecycleMethods
};
</script>

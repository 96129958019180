import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";
import {
  SET_ERROR as COMMIT_SET_ERROR,
  SET_ITEMS as COMMIT_SET_ITEMS,
  SET_LOADING as COMMIT_SET_LOADING
} from "@/store/templates/table/mutation-types";

const fetchCall = api.question.fetchAll;
const fetchCallAlwaysVisible = api.question.fetchAllAlwaysVisibleQuestions;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall });
  },
  async fetchAlwaysVisible({ commit }) {
    // Clear error state, set load state.
    commit(COMMIT_SET_LOADING, true);
    commit(COMMIT_SET_ERROR, false);
    try {
      const filters = { alwaysVisible: true };
      // Make the provided api call.
      const { entities, result, meta } = await fetchCallAlwaysVisible({
        filters
      });
      // Set items & clear load state on success
      commit(COMMIT_SET_ITEMS, { ids: result, entities, meta });
      commit(COMMIT_SET_LOADING, false);
      return result;
    } catch (e) {
      console.error("Failed to retrieve always visible questions:", e);
      // Clear load state and set error state on failure
      commit(COMMIT_SET_LOADING, false);
      commit(COMMIT_SET_ERROR, true);
      console.error(e);
    }
  }
};

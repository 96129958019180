<template>
  <div>
    <v-card color="white">
      <crud-header
        type="Category"
        store="category"
        icon="far fa-fw fa-tags"
        @save-success="
          ({ id }) =>
            $router.push({
              name: 'categories.show',
              params: { categoryId: id }
            })
        "
      />
      <category />
    </v-card>
  </div>
</template>

<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CrudHeader from "../../components/generic/crud/CrudHeader";
import categoryModule from "@/store/modules/category";
import { mapCreateViewLifecycleMethods } from "../helpers";
import questionsModule from "@/store/modules/questions";
import store from "@/store";
import Category from "@/components/category/Category";

const { mapGetters } = createNamespacedHelpers("category");
const createViewLifecycleMethods = mapCreateViewLifecycleMethods(
  "category",
  categoryModule
);

export default {
  name: "category-create-view",
  $_veeValidate: {
    validator: "new" // give me my own validator scope.
  },
  components: {
    Category,
    CrudHeader
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["id"]),
    tabs() {
      return [
        {
          name: "Dashboard",
          to: { name: "categories.create.show" }
        },
        {
          name: "Subject Mappings",
          to: {
            name: "categories.create.subject-mappings"
          }
        }
      ];
    }
  },
  created() {
    this.registerQuestions();
  },
  methods: {
    registerQuestions() {
      store.registerModule("questions", questionsModule);
      this.$store.dispatch("questions/fetchAlwaysVisible");
    }
  },
  ...createViewLifecycleMethods
};
</script>

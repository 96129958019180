<template>
  <v-chip label small :selected="selected">
    <v-icon small>far fa-fw fa-tag</v-icon>
    {{ category.name.nl_be }}
  </v-chip>
</template>
<script type="text/babel">
export default {
  name: "v-chip-category",
  props: {
    category: {
      type: Object,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    }
  }
};
</script>

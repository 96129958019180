<template>
  <v-card>
    <v-card-text>
      <category-subject-question
        v-for="(question, i) in questions"
        :key="question.id"
        :question="question"
        :category-subject="categorySubject"
        :category-subject-index="categorySubjectIndex"
        :specifier="getSpecifierForQuestion(question)"
        :odd="i % 2 === 1"
        @specifier-add="addSpecifier(question)"
        @specifier-remove="removeSpecifier(question)"
      />
    </v-card-text>
  </v-card>
</template>
<script type="text/babel">
import CategorySubjectQuestion from "@/components/category/CategorySubjectQuestion";

export default {
  name: "category-subject",
  components: { CategorySubjectQuestion },
  props: {
    categorySubject: {
      type: Object,
      default: () => {}
    },
    categorySubjectIndex: {
      type: Number,
      required: true
    }
  },
  data: () => {
    return {};
  },
  computed: {
    subject() {
      return this.$store.getters["category/subject"](
        this.categorySubject.subject
      );
    },
    questionIds() {
      return this.subject.questions;
    },
    questions() {
      const alwaysVisibleQuestions = this.$store.getters[
        "questions/alwaysVisibleQuestionsNormalized"
      ];
      const subjectQuestions = this.$store.getters[
        "category/getQuestionsByIdsNormalized"
      ]({
        ids: this.questionIds,
        types: ["SELECT", "INPUT_RADIO", "INPUT_CHECKBOX"]
      });

      return alwaysVisibleQuestions
        .concat(subjectQuestions)
        .filter(question => {
          return question.isFilterable;
        });
    }
  },
  watch: {},
  methods: {
    getSpecifierForQuestion(question) {
      const ids = {
        categorySubjectIndex: this.categorySubjectIndex,
        rootQuestionId: question.rootId
      };
      return this.$store.getters["category/getSpecifierForQuestion"](ids);
    },
    addSpecifier(question) {
      const defaultSpecifier = {
        id: null,
        type: "EXACT",
        question: question.rootId,
        options: []
      };
      this.$store.commit("category/addSpecifier", {
        categorySubjectIndex: this.categorySubjectIndex,
        defaultSpecifier: defaultSpecifier
      });
    },
    removeSpecifier(question) {
      this.$store.commit("category/removeSpecifier", {
        categorySubjectIndex: this.categorySubjectIndex,
        question: question
      });
    }
  }
};
</script>

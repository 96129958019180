<template>
  <v-text-field
    v-model="search"
    append-icon="search"
    label="Search"
    solo-inverted
    flat
    single-line
    hide-details
    height="20"
    class="pl-3"
  />
</template>
<script>
import { FILTERS as GETTERS_FILTERS } from "../../../store/templates/table/getter-types";
import { SET_FILTER as COMMIT_SET_FILTER } from "../../../store/templates/table/mutation-types";
import { FETCH as DISPATCH_FETCH } from "../../../store/templates/table/action-types";

export default {
  name: "overview-header-search",
  props: {
    store: {
      type: String,
      required: true
    },
    auto: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    timeout: undefined
  }),
  computed: {
    search: {
      get() {
        return this.$store.getters[`${this.store}/${GETTERS_FILTERS}`].search;
      },
      set(value) {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.$store.dispatch(`${this.store}/${DISPATCH_FETCH}`);
        }, 500);
        this.$store.commit(`${this.store}/${COMMIT_SET_FILTER}`, {
          prop: "search",
          value
        });
      }
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"color":"white"}},[_c('crud-header',{attrs:{"type":"Category","store":"category","icon":"far fa-fw fa-tags"},on:{"save-success":function (ref) {
                    var id = ref.id;

                    return _vm.$router.push({
            name: 'categories.show',
            params: { categoryId: id }
          });
}}}),_c('category')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
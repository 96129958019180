<template>
  <div>
    <v-expansion-panel>
      <v-expansion-panel-content
        v-for="(categorySubject, index) in categorySubjects"
        :key="categorySubject.subject"
      >
        <div slot="header">
          {{ getSubjectName(categorySubject.subject) }}
          <v-btn
            flat
            icon
            color="primary"
            @click="removeSubject(index)"
            justify-end
          >
            <v-icon>far fa-trash-alt</v-icon>
          </v-btn>
        </div>
        <category-subject
          :category-subject="categorySubject"
          :category-subject-index="index"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-alert
      v-if="categorySubjects.length === 0 && !addSubject"
      key="category-subject-alert"
      :value="true"
      outline
      color="info"
      icon="info"
    >
      This category has no mapped subjects.
    </v-alert>
    <v-container v-if="addSubject">
      <v-autocomplete-subject v-model="subject" />
      <v-layout row justify-end>
        <v-btn @click="addSubject = false">cancel</v-btn>
        <v-btn @click="addNewSubject">add</v-btn>
      </v-layout>
    </v-container>
    <v-btn fixed dark fab bottom right color="pink" @click="addSubject = true">
      <v-icon>add</v-icon>
    </v-btn>
  </div>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import CategorySubject from "../../components/category/CategorySubject";
import { isNil } from "lodash";
import VAutocompleteSubject from "@/components/subject/VAutocompleteSubject";
import api from "@/api";
const fetchSubjectFunction = api.subject.fetchCategorySubject;
const { mapGetters } = createNamespacedHelpers("category");

export default {
  name: "category-subject-mappings",
  components: { VAutocompleteSubject, CategorySubject },
  data() {
    return {
      subject: null,
      addSubject: false
    };
  },
  computed: {
    ...mapGetters(["categorySubjects"])
  },
  methods: {
    getSubjectName(subjectId) {
      const subject = this.$store.getters["category/subject"](subjectId);
      if (!isNil(subject)) {
        return subject.name;
      }
      return "";
    },
    async addNewSubject() {
      const data = await fetchSubjectFunction(this.subject);
      this.$store.commit("category/addSubject", {
        entities: data.entities,
        subjectId: this.subject
      });
    },
    removeSubject(index) {
      this.$store.commit("category/removeSubject", index);
    }
  }
};
</script>

<template>
  <v-layout align-center :class="{ 'grey lighten-3': odd }">
    <v-flex xs2 md1>
      <v-checkbox v-model="enabled" hide-details class="shrink mr-2" />
    </v-flex>
    <v-flex xs10 md6>
      <v-layout row wrap>
        <v-flex xs12 :class="{ 'grey--text': !enabled }">
          {{ question.label.nl_be }}
        </v-flex>
        <v-flex v-for="(option, i) in question.options" :key="i" xs12 md6>
          <v-checkbox
            v-model="specifierOptions"
            :label="getOptionLabel(option).nl_be"
            :value="option.id"
            :disabled="!enabled"
            hide-details
            class="mt-0"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex xs12 md5>
      <v-select
        v-model="specifierType"
        :items="questionSpecifierTypes"
        label="Type"
      />
    </v-flex>
  </v-layout>
</template>
<script type="text/babel">
import { isNil } from "lodash";

export default {
  name: "category-subject-question",
  props: {
    specifier: {
      type: Object,
      required: false
    },
    categorySubject: {
      type: Object,
      default: () => {}
    },
    question: {
      type: Object,
      required: true
    },
    odd: {
      type: Boolean,
      default: false
    },
    categorySubjectIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      questionSpecifierType: "ANY"
    };
  },
  computed: {
    enabled: {
      get() {
        return !isNil(this.specifier);
      },
      set(value) {
        this.$emit(`specifier-${value ? "add" : "remove"}`, {
          question: this.question
        });
      }
    },
    specifierOptions: {
      get() {
        return this.enabled ? this.specifier.options : [];
      },
      set(value) {
        const specifier = {
          categorySubjectIndex: this.categorySubjectIndex,
          rootQuestionId: this.question.rootId,
          options: value
        };
        this.$store.commit("category/setSpecifierOptions", specifier);
      }
    },
    specifierType: {
      get() {
        return this.enabled ? this.specifier.type : null;
      },
      set(value) {
        const specifier = {
          categorySubjectIndex: this.categorySubjectIndex,
          rootQuestionId: this.question.rootId,
          type: value
        };
        this.$store.commit("category/setSpecifierType", specifier);
      }
    },
    questionSpecifierTypes() {
      return [
        { text: "Any", value: "ANY" },
        { text: "At least", value: "AT_LEAST" },
        { text: "Exact", value: "EXACT" },
        { text: "Except", value: "EXCEPT" }
      ];
    }
  },
  methods: {
    getOptionLabel(option) {
      if (isNil(option.label) && !isNil(option.parent)) {
        return this.getOptionLabel(option.parent);
      }
      return option.label;
    }
  }
};
</script>

import tableGetters from "@/store/templates/table/getters";
import {
  assignInWith,
  cloneDeep,
  defaults,
  isNil,
  isObject,
  map
} from "lodash";
import { denormalize } from "normalizr";
import { question as questionSchema } from "@/store/schema";

export default {
  ...tableGetters,
  alwaysVisibleQuestions: state => state.ids.map(id => state.entity[id]),
  alwaysVisibleQuestionsNormalized: (state, getters) =>
    getters.alwaysVisibleQuestions.map(question =>
      denormalize(question, questionSchema, state)
    ),
  getQuestionOptionById: (state, getters) => ({ id }) => {
    let questionOption = cloneDeep(state.questionOption[id]);
    if (!isNil(questionOption) && !isNil(questionOption.parent)) {
      const parentQuestionOption = getters.getQuestionOptionById({
        id: questionOption.parent
      });
      assignInWith(
        questionOption,
        parentQuestionOption,
        (objValue, parentValue) => {
          if (isObject(objValue) || isObject(parentValue)) {
            // Double loop for localization fields
            assignInWith(
              objValue,
              parentValue,
              (secObjValue, secParentValue) => {
                return isNil(secObjValue) ? secParentValue : secObjValue;
              }
            );
          }
          return isNil(objValue) ? parentValue : objValue;
        }
      );
      questionOption = defaults(questionOption, parentQuestionOption);
    }
    if (isNil(questionOption)) {
      console.warn(
        `Tried to retrieve entity questionOption with id ${id} but received ${questionOption}`
      );
    }
    return questionOption;
  },
  getQuestionOptionsByIds: (state, getters) => ids => {
    return map(ids, id => getters.getQuestionOptionById({ id: id }));
  }
};
